/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  less dependencies when defined in the bower.json of your dependencies
 */
// bower:less
// endbower

/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap/less/variables.less
 */
@navbar-inverse-link-color: #5AADBB;
@icon-font-path: '../../bootstrap/fonts/';

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.thumbnail {
    height: 200px;

    img.pull-right {
        width: 50px;
    }
}



.article {

}


img {

    height: 120px;
}


.description {
    /*  min-width: 30vh; */
}

.action {
    margin-bottom: 0px;
    margin-top: 10px;
}

.details {
    margin-top: 10px;
}

.title {
    font-family: fantasy;
    font-weight: bold;
    color: red;
}

.quantity {
    margin-left: 0px;
}

.image {
    width: 20px;
}

.taille {
    /*margin-left: 30px;*/
    padding-left: 20px;
}

.price {
    /* float: right;*/

}

.add {
    margin-top: 10px;
}

ul, ol, li {
    list-style: none;
}

ul {
    margin: 0px;
    padding: 0px;
}


.well {
    background: none;
    height: 320px;
}

.table-scroll tbody {
    position: absolute;
    overflow-y: scroll;
    height: 250px;
}

.table-scroll tr {
    width: 100%;
    table-layout: fixed;
    display: inline-table;
}

.table-scroll thead > tr > th {
    border: none;
}



@media(max-width:767px){

    .container {
        padding-left:0px;
        padding-right:0px;
    }

}


#usertable  {

    td.edit {
        width: 30px;
    }
    td.id {
        width: 30px;
    }
    td.address {
        background-color: lightcyan;
        min-width: 100px;
    }
    td.postalcode {
        background-color: lightskyblue;
        min-width: 50px;
    }
    td.phone {
        background-color: lightseagreen;
        min-width: 70px;
    }
    td.gps {
        min-width: 50px;
    }
    td.date {
        min-width: 30px;
    }
}












/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your less files automatically
 */
// injector
// endinjector
