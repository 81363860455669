
.menuimg {
    height: 50px;
}

.navbar {
    margin-bottom:0px;
}

.navbar-nav > li > a {
float: left!important;
}
.navbar-nav>li, .navbar-nav {
  float: left !important;
}

.navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
    float: left;
}

.navbar-default .navbar-nav > li > a {
    color: #777;
    float: left;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    float: left;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    float: left;
}